.gc-project-dashboard {
    .ant-btn-link {
        border: none;
        @apply h-fit;
    }
    .ant-btn-link:hover, .ant-btn-link:focus {
        border: none;
        color: #000;
        text-shadow: black;
    }

    .box-border {
        border: 1px solid #3B3B3B4D;
    }

    .bottom-border {
        border-bottom: 1px solid #3B3B3B4D;
    }

    .box-header {   
        border-bottom: 1px solid #3B3B3B4D; 
        color: #00000066; 
        background-color: #F7F6F4;  
        @apply w-full h-10 flex items-center px-2 uppercase font-semibold
     }
     .ag-theme-alpine {
        --ag-header-background-color: #F7F6F4;
     }

     .ag-theme-alpine .ag-header-cell-text {
        color: #00000066;
     }

     .alert-message {
        background-color: #3B3B3B26;
        @apply h-8 flex items-center text-xs justify-center
     }

     .text-matrix-box {
        @apply text-[#3B3B3B] font-medium
     }
}