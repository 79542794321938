.material-list-page {

  .ag-cell-focus,
  .ag-cell {
    border: none !important;
  }

  .matnameheadertooltip {
    @apply flex-col justify-center bg-white max-w-lg p-2 border border-solid border-gray-300 text-gray-600 space-y-1 rounded;
  }

  .request-durations-modal {
    .ant-modal-header {
      border-color: #00000033 !important;
    }
  }

  .linked-submittals-cell {
    .ag-cell-wrapper {
      align-items: stretch !important;
    }
  }


}
