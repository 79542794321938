@import url("https://fonts.googleapis.com/css?family=Inter");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~antd/dist/antd.css";

* {
  font-family: "Inter";
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f7f6f4 0% 0% no-repeat padding-box;
  opacity: 1;
}

/* (1.76 * window.innerHeight) / 100 */

:root {
  --font-size-one: 0.839rem;
  --line-height-one: 0.978rem;

  --font-size-two: 0.92rem;
  --line-height-two: 1.11rem;

  --font-size-three: 0.744rem;
  --line-height-three: 0.929rem;

  --font-size-four: 0.653rem;
  --line-height-four: 0.791rem;

  --font-size-five: 0.744rem;
  --line-height-five: 1.116rem;

  --line-height-six: 2rem;
}

.text-one {
  /* font: normal normal normal 1.76vh/2.05vh Inter; */
  font-size: var(--font-size-one) !important;
  line-height: var(--line-height-one) !important;
  font-weight: normal;
}

.text-two {
  /* normal normal normal 1.95vh/2.34vh Inter */
  font-size: var(--font-size-two) !important;
  line-height: var(--line-height-two) !important;
  font-weight: normal;
}

.text-three {
  /* normal normal normal 1.56vh/1.95vh Inter */
  font-size: var(--font-size-three) !important;
  line-height: var(--line-height-three) !important;
  font-weight: normal;
}

.text-four {
  /* normal normal normal 1.37vh/1.66vh Inter */
  font-size: var(--font-size-four) !important;
  line-height: var(--line-height-four) !important;
  font-weight: normal;
}

.text-five {
  /* font: normal normal normal 1.56vh/2.34vh Inter; */
  font-size: var(--font-size-five) !important;
  line-height: var(--line-height-five) !important;
  font-weight: normal;
}

.text-six {
  /* font: normal normal normal 1.76vh/4.49vh Inter; */
  /* Combination of one and six */
  font-size: var(--font-size-one) !important;
  line-height: var(--line-height-six) !important;
  font-weight: normal;
}

.text-color-one {
  color: #000000 !important;
}

.text-color-two {
  color: #0000007f !important;
}

.captialize-first::first-letter {
  text-transform: uppercase;
}

.gridIdLink {
  @apply font-medium underline text-black decoration-dashed underline-offset-2 decoration-1 hover:underline hover:decoration-1 hover:decoration-dashed;
}

.ag-cell-wrapper {
  height: 100%;
}

.gridNameLink {
  @apply underline text-[#434748] decoration-dashed underline-offset-2 decoration-1 hover:underline hover:decoration-1 hover:decoration-dashed;
}

.form-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f !important;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
  text-transform: uppercase;
  letter-spacing: 0.07em;
  font-weight: 590 !important;
  font: normal normal normal 14px/17px Inter;
}

.form-field-heading {
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #555;
  font-weight: 590 !important;
  font: normal normal normal 14px/17px Inter;
  font-size: 0.75em !important;
}

.form-field-heading-2 {
  height: auto;
  text-transform: uppercase;
  letter-spacing: 0.05em;
  color: #555;
  font-weight: 590 !important;
  font: normal normal normal 14px/17px Inter;
  font-size: 0.85em !important;
}

.custom-drawer .ant-modal-header {
  border-bottom: 1px solid #3B3B3B4D;
  background: #F7F6F4;
}

.custom-drawer .ant-modal-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #3B3B3BE5;
}

.custom-drawer .ant-modal-body {
  padding: 15px 0px 24px 0px;
}

.submittal-insights .ant-modal-header {
  background: #F7F6F4;
}

.underlined-text {
  @apply underline decoration-dashed underline-offset-2 decoration-1 hover:underline hover:decoration-1 hover:decoration-dashed;
}

.line-clamp2 {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 2;
}

.grid-header-style {
  background-color: #F7F6F4 !important;
}

.notifications .notification-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 14.52px;
  text-align: left;
  color: #3b3b3bCC;
}

.notifications .notification-highlight-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 14.52px;
  text-align: left;
  color: #3b3b3bCC;
}

.notifications .notification-subTitle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 12.1px;
  text-align: left;
  color: #3b3b3bCC;
}

.notifications .notification-highlight-subTitle {
  font-family: Inter;
  font-size: 12px;
  font-weight: 600;
  line-height: 12.1px;
  text-align: left;
  color: #1D92FF;
}

.ag-cell-inline-editing {
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.gridButtonLink {
  @apply font-medium text-black underline-offset-2 decoration-1 m-0 p-0 bg-transparent border-0 cursor-pointer;
  transition: color 0.3s;
}

.gridButtonLink:hover {
  @apply underline decoration-dashed text-[#40a9ff];
}

.gridButtonLink:disabled {
  opacity: 0.5;
  @apply no-underline text-black;
}

.ciq-button-1 {
  border: 0;
  border-bottom: 1px solid transparent;
  color: #1890ff !important;
  margin: 0;
  padding: 0;
  border-radius: 0;
}

.ciq-button-1:hover,
.ciq-button-1:focus,
.ciq-button-1:active {
  border-bottom: 1px solid #1890ff !important;
  border-color: #1890ff !important;
}

.ciq-button-2 {
  border: 0;
  border-bottom: 1px solid transparent;
  color: #1890ff !important;
  margin: 0;
  padding: 0;
  border-radius: 0
}

.ciq-button-2:hover,
.ciq-button-2:focus,
.ciq-button-2:active {
  border-bottom: 1px solid #1890ff !important;
}

.ciq-icon-button {
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  outline: none;
}

.ciq-icon-button:disabled {
  background: transparent
}

.entity-id-with-spec{
  padding-right: 2px;
  /* -webkit-padding-end: 2px; */
}