@import '../../assets/scss/var.scss';

/* block */
.viz-block {
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  /* border-right: none; */
  width: 26px;
  height: 24px;
  text-align: center;
  background-color: white;
  position: relative;
  cursor: default;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viz-block--first {
  border-radius: 4px 0 0 4px;
}

.viz-block--wrapper:first-child .viz-block {
  border-left: 1px solid black;
}

.viz-block--last {
  border-radius: 0 4px 4px 0;
}

.viz-block.viz-block--completed-rejected {
  background-color: #ffff00;
}

.viz-block.viz-block--completed-rejected:hover {
  background-color: #ffcc00;
}

.viz-block.viz-block--completed-not-rejected {
  background-color: $date-block-on-track-bg-color;
}

.viz-block.viz-block--completed-not-rejected:hover {
  background-color: $date-block-on-track-bg-color;
}

.viz-block.viz-block--completed-unknown {
  background-color: #d5d5d5;
}

.viz-block.viz-block--completed-unknown:hover {
  background-color: #cac9c9;
}

.viz-block.viz-block--in-progress {
  background-color: #333;
  color: white;
}

.viz-block.viz-block--in-progress:hover {
  background-color: black;
}

.viz-block--cancelled {
  position: absolute;
  width: 1px;
  height: 100%;
  border-left: 1px solid rgb(0, 0, 0, 0.7);
  transform: rotate(45deg);
}

/* button */

.viz-block--button {
  width: 24px;
  height: 24px;
  border: none;
  border: 1px solid transparent;
  padding: 1px;
  cursor: pointer;
  background-color: transparent;
  opacity: 0.8;
}

.viz-block--button:hover {
  opacity: 1;
}

.viz-block--button:disabled {
  opacity: 0.2;
}

.viz-block--button svg {
  display: inherit;
  margin-top: -2px;
  margin-left: -2px;
}

/* sizes */

.viz-block--small .viz-block,
.viz-block--small .viz-block--button {
  width: 17px;
  height: 16px;
  font-size: 9px;
  margin-top: -10px;
}

.viz-block--small .viz-block--first {
  border-radius: 2px 0 0 2px;
}

.viz-block--small .viz-block--last {
  border-radius: 0 2px 2px 0;
}

.viz-block--large .viz-block,
.viz-block--large .viz-block--button {
  width: 32px;
  height: 32px;
  font-size: 20px;
}

.viz-block--large .viz-block--first {
  border-radius: 8px 0 0 8px;
}

.viz-block--large .viz-block--last {
  border-radius: 0 8px 8px 0;
}

.viz-block--medium .viz-block {
  font-size: 12px;
}