.invite-project-companies{
    .ant-modal-header {
        border-bottom: 1px solid #3B3B3B4D;
        background: #F7F6F4;        
      }

      .ant-modal-title{
        font-family: Inter;
        font-size: 16px;
        font-weight: 500;
        line-height: 19px;
        letter-spacing: 0em;
        text-align: left;
        color: #3B3B3BE5;
      }
      .ant-form-item .ant-form-item-label label {
       
        color: #3B3B3B;
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        text-align: left;

    }

    .ant-modal-body {
        padding: 24px 0px 24px 0px;
    }

    .add-more-button{
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        color: #3B3B3B;
        width: 80px;
        height: 30px;
    }

    .add-companies-btn{
        height: 30px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 500;
        line-height: 15px;
        letter-spacing: 0em;
        color:#FFFFFF;
    }

}
   